import { observable, action } from "mobx";
var jwtDecode = require("jwt-decode");

const KEY = "TOKEN";

const localStorage = window.localStorage
const sessionStorage = window.sessionStorage

export class UserService {

    @observable
    user = {
        logged: false,
        name: "",
        id: ""
    };

    private token: string | null = null;

    constructor() {
        this.init();
    }


    @action
    clean() {
        console.log("UserService Clean");
        this.user.logged = false;
        this.token = "";
        this.user.id = "";
        this.user.name = "";
        if (document.location.hostname === "localhost") { 
            document.cookie = "setoken=; secure;lax; domain=localhost; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        } else {
            document.cookie = "setoken=; secure;lax; domain=simulationengine.io; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        }
        
    }

    setToken(token: string, storage: boolean) {
        let cookie = "setoken=" + token + ";secure;lax;path=/";

        if (storage) {  
            cookie += ";max-age=2505600"; //29 days
        }

        if (document.location.hostname === "localhost") { 
            cookie += ";domain=localhost";
        } else {
            cookie += ";domain=simulationengine.io";
        }
        document.cookie = cookie;
        this.initToken(token);
    }

    private init() {
        console.log("Init UserService");
        
        let token = null;
        if (document) {
            const cookie = document.cookie.split('; ')
                .find(row => row.startsWith('setoken'))
                
            if (cookie) {
                const parts = cookie.split('=');
                token = parts[1];
            }
        }

        if (token) {
            this.initToken(token);
        } else {
            this.clean();
        }
    }

    @action
    private initToken(token: string) {
        let decoded;
        try {
            decoded = jwtDecode(token);
        } catch(e) {
            //Invalid token
            console.log("Invalid token. Ignoring");
            this.clean();
        }
        this.token = token;
        this.user.id = decoded.sub;
        this.user.name = decoded.name;
        this.user.logged = true;
    }
}

export const userService = new UserService();