import React from 'react';
import {
    useHistory,
    Link as LinkRouter
} from "react-router-dom";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export function useGoTo(path: string, search?: string) {
    console.log(path);
    const url = 
        window.location.protocol + 
        "//" + window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        path +
        (search ? "?" + search : "");

    console.log("Going to: ", url);
    window.location.assign(url);
    // if (search) {
    //     window.location.search = search;
    // } else {
    //     window.location.search = "";
    // }


    // useHistory().push(location)
    // window. = "/";
}

export interface LinkProp {
    children: string,
    className?: string,
    newTab?: boolean,
    to: string,
    id: string,
}

export function Link(props: LinkProp) {
    {/*// <LinkRouter className="btn btn-sm btn-round btn-primary" to="signup">Sign up</Link>
        //FOr now does a full page render to avoid issues with template.*/}
    return (
        <a id={props.id} target={props.newTab ? "_blank" : undefined} 
            className={props.className} href={"/" + props.to}>{props.children}</a>
    );
}