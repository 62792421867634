import React, { Component, MouseEvent } from 'react';
import { Header } from '../components/Header';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../components/Link';
import { userService } from '../service/UserService';
import { HelpOptionsPanel } from './knowledge/help/HelpOptionsPanel';

@observer
export class SupportPage extends Component<{}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Header navClassName="navbar navbar-expand-lg navbar-dark navbar-stick-dark" />
                <header className="header" style={{paddingTop: 100, paddingBottom: 50, backgroundImage: "linear-gradient(135deg, #f9f7ff 0%, #fff 50%, #f6f3ff 100%)"}}>
                    <div className="container">
                        <h3>Support</h3>
                    </div>
                </header>
                
                <main className="main-content">
                    <HelpOptionsPanel />
                </main>
            </React.Fragment>
        );
    }
}
