import React from 'react';
import './App.css';
import { Footer } from './components/Footer';
import { HomeLandPage } from './pages/HomeLandPage';
import { SingupPage } from './pages/account/SignupPage';
import { ConfirmationPage } from './pages/account/ConfirmationPage';
import { RecoverPasswordPage } from './pages/account/RecoverPasswordPage';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { LoginPage } from './pages/account/LoginPage';
import { DashboardPage } from './pages/DashboardPage';
import { KnowledgePage } from './pages/knowledge';
import { SupportPage } from './pages/SupportPage';
import { SimulationBasicsPage } from './pages/knowledge/help/SimulationBasicsPage';
import { HelpBasicPage } from './pages/knowledge/help/HelpBasicComponentsPage';
import { ExamplesPage } from './pages/knowledge/help/ExamplesPage';
import { HelpAggregatorComponentsPage } from './pages/knowledge/help/HelpAggregatorComponentsPage';
import { ScriptingHelpPage } from './pages/knowledge/help/ScriptingHelp';

// var page = require('./template/js/page');

// import * as page from ;

export class App extends React.Component {


    //back to top after navigate
    componentDidMount() {
        window.scrollTo(0, 0);
        this.configPage();
    }

    configPage() {
        // var $ = window.jQuery;
        // var page = {
        //     name: 'SimulationEngine.io',
        //     version: '1.0.0',
        //     vendors: [],

        //     body: $('body'),
        //     navbar: $('.navbar'),
        //     header: $('.header'),
        //     footer: $('.footer'),
        // };

        // page.defaults = {
        //     googleApiKey: '',
        //     googleAnalyticsId: 'UA-163898122-1',

        //     reCaptchaSiteKey:  '',
        //     reCaptchaLanguage: '',
        //     disableAOSonMobile: true,
        //     smoothScroll: true
        // };

        // page.config(page.defaults);
    }

    render() {
        console.log("Routing to: " + window.location.href);
        return (
            <Router>
                <Switch>
                    <Route path="/account/confirmation">
                        <ConfirmationPage />
                    </Route>
                    <Route path="/account/signup">
                        <SingupPage />
                    </Route>
                    <Route path="/account/login">
                        <LoginPage />
                    </Route>
                    <Route path="/account/recover">
                        <RecoverPasswordPage />
                    </Route>
                    <Route path="/dashboard">
                        <DashboardPage />
                    </Route>
                    <Route path="/knowledge">
                        <KnowledgePage />
                    </Route>

                    <Route path="/support/started/basic">
                        <SimulationBasicsPage />
                    </Route>
                    <Route path="/support/started/examples">
                        <ExamplesPage />
                    </Route>
                    <Route path="/support/help/basic">
                        <HelpBasicPage />
                    </Route>
                    <Route path="/support/help/aggregator">
                        <HelpAggregatorComponentsPage />
                    </Route>
                    <Route path="/support/help/scripting">
                        <ScriptingHelpPage />
                    </Route>
                    <Route path="/support">
                        <SupportPage />
                    </Route>
                    <Route path="/">
                        <HomeLandPage />
                    </Route>
                </Switch>
                <Footer />
            </Router>
        );
    }

}

export default App;
