import React, { Component, MouseEvent } from 'react';
import { Header } from '../../../components/Header';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../../components/Link';
import { basicUrl } from './ExamplesPage';

@observer
export class SimulationBasicsPage extends Component<{}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Header navClassName="navbar navbar-expand-lg navbar-light navbar-stick-dark" />
                <header className="header text-white bg-dark pt-9 pb-5" style={{backgroundImage: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)"}}>
                    <div className="container">
                        <h3>Knowledge Base</h3>
                    </div>
                </header>
                
                <main className="main-content">
                    <nav className="bb-1" aria-label="breadcrumb">
                        <div className="container">
                        <ol className="breadcrumb px-0">
                            <li className="breadcrumb-item"><a href="/knowledge">Knowledge Base</a></li>
                            <li className="breadcrumb-item"><a href="/knowledge">Help</a></li>
                            <li className="breadcrumb-item">Simulation basics</li>
                        </ol>
                        </div>
                    </nav>

                    <div className="container">
                        <div className="row">

                            {/* 
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                            | Article
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒ 
                            */}
                            <div className="col-md-7 col-xl-8 mr-md-auto py-8">
                                <article>
                                    <h2 id="EntitiesAndComponents" className="fw-200">Simulation entities and simulation Components</h2>
                                    <p>
                                        A computer simulation is an attempt to model a real-life or hypothetical situation on a computer so that it can be studied to see how the system works. By changing variables in the simulation, predictions may be made about the behaviour of the system. It is a tool to virtually investigate the behaviour of the system under study. (Wikipedia)
                                    </p>

                                    <p>
                                    SimulationEngine.io offers an intuitive Studio where a simulation model can be built, without any programming knowledge, by drag-and-drop components. SimulationEngine.io models are built using two types of elements: Simulation Components and Simulation Entities.

                                    </p>

                                    <p>
                                        <strong>Simulation Components</strong> implements part of the real-life process on the simulation. They are responsible for applying specific behaviour on the simulation entities. 
                                    </p>

                                    <p>
                                        <strong>Simulation Entities</strong> model the entities of the real-life process we are simulating.
                                    </p>

                                    <p>
                                        To help fix these concepts, let's built a small simulation model as an example: A department store is having issues with 
                                        the waiting time in its cashiers due to increase in customers number at lunchtime. The store has 2 cashiers 
                                        attending a two separated queue of customers, one for each cashier. The store has analyzed the cashier 
                                        process determined that the cashier's service time can be approximated by an Exponential distribution 
                                        with mean equals to 120 seconds, and customers arrive at the queues following an Exponential distribution 
                                        with mean equals to 70 seconds. The store wants to verify if moving to a single queue for both cashiers will 
                                        help reduce the waiting time. Click <a target="_blank" href={basicUrl}>here</a> to open this examples on the SimulationEngine.io Studio. 
                                    </p>

                                    <p>
                                        In this example, clients are the simulation entities. The cashiers attend each client. The clients are the ones receiving the service. Clients arrive at the Queues, on the simulation they are "generated" by a Producer.
                                    </p>

                                    <p>
                                        The Producer, Queues, Servers and Sink are the Simulation components which implement different aspects of the model.<br />
                                        The Producer simulates the customers arriving at the cashier lines.<br />
                                        The Queues represent the waiting lines for the cashiers.<br />
                                        The Servers simulates the cashier work.<br />
                                        The Sink represents customers leaving the cashiers after being served.
                                    </p>
                                    
                                    <hr />

                                    <h2 id="DiscreteEventSimulator" className="fw-200">Discrete-Event Simulator</h2>
                                    <p>
                                        SimulationEngine.io implements a Discrete-Event Simulator underneath. 
                                    </p>
                                    
                                    <p>
                                        At a Discrete Event Simulator, every event happens at the exactly simulation time it is supposed to happen. This gives it the power to closely represents the real-life process.
                                    </p>

                                    <p>
                                        As an example, suppose you have a Producer that creates entities following the Constant distribution, with value equals to 10. This Producer will generate the first entity at time 10, the second at time 20, and another one at times 30, 40, ... precisely. 
                                    </p>
                                    
                                    <p>
                                        SimulationEngine.io allows you to define one Time Unit (s - seconds; m - minutes; h - hours)
                                         for your whole model. Click on an empty part of your model 
                                         (unselect all simulation components), and you will see the value on the Simulation 
                                         property tab. There, you will also find the <strong>"Time limit (simulation time)"</strong>, 
                                         which defines the maximum simulation time your model will be simulated.
                                    </p>

                                    <p>
                                        Simulation time is the time elapsed on the model you are simulating. On the other hand, we have the real-time, 
                                        which is the time it will take SimulationEngine.io to run your simulation model.
                                    </p>

                                    <p>
                                        As an example, suppose you have a Server that represents a repair on a broken car. 
                                        The service time follows a Uniform distribution with min equals to 1 hour and max equals to 10 hours. 
                                        Those times are in "Simulation time", while SimulationEngine.io will be able to simulate a car repair 
                                        in a fraction of a millisecond (real-time).
                                    </p>

                                </article>
                            </div>


                            {/* 
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                            | Sidebar
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒ 
                            */}
                            <div className="col-md-4 col-xl-3 d-none d-md-block">
                                <aside className="sidebar">
                                <h6>Topics</h6>
                                <ul className="nav flex-column">
                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#EntitiesAndComponents">Entities and Components</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#DiscreteEventSimulator">Discrete Event Simulator</a>
                                    </li>

                                </ul>
                                </aside>
                            </div>

                        </div>
                    </div>
                    
                </main>
            </React.Fragment>
        );
    }
}
