import React, { Component, MouseEvent } from 'react';
import { PricePanel } from '../../components/PricePanel';
import { Header } from '../../components/Header';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../components/Link';
import Validator from 'validatorjs';
import { confirm } from '../../service/APIProxy';


type Fields = {[K in string]: {value: any, error: boolean, rule: string}};
class ConfirmationStore {
    @observable
    fields: Fields = {
        email: {
          value: '',
          error: true,
          rule: 'required|email'
        },
        code: {
          value: '',
          error: false,
          rule: 'required'
        }
    };

    meta = {
      isValid: false,
      error: null,
      processing: false,
      emailVisible: false
    };

    msg = "";

    @action
    setEmail(email: string) {
        this.onFieldChange("email", email);
    }

    @action
    setMsg(msgCode: string) {
        if (msgCode === "ACCOUNT_CREATED") {
            this.msg = "Your account was created. A confirmation code was sent to your email. Please enter it here to confirm your account."
        } else if (msgCode === "ACCOUNT_EXISTS") {
            this.msg = "Your account is already registered. Look your email for the confirmation code. Please enter it here to confirm your account."
        } else {
            console.log("Unknwon msgCode: " + msgCode)
        }
    }


    @action
    onFieldChange(field: string, value: any) {
        this.fields[field].value = value;
        const validationConf:any = {};
        const validationValue:any = {};
        
        Object.keys(this.fields).forEach((field) => validationConf[field] = this.fields[field].rule);
        Object.keys(this.fields).forEach((field) => validationValue[field] = this.fields[field].value);
        const validation = new Validator(validationValue, validationConf);

        this.meta.isValid = validation.passes() as boolean;

        this.fields[field].error = validation.errors.first(field) ? true : false;
    };
}

@observer
export class ConfirmationPage extends Component {

    @observable
    private store = new ConfirmationStore();

    @observable
    private error = "";

    @observable
    private errorCode = "";

    constructor(props: {}) {
        super(props);
        const params = new URLSearchParams(window.location.search);

        if (params.get('msg')) {
            this.store.setMsg(params.get('msg') as string);
        }

        console.log("Email: " + params.get('email'));
        if (params.get('email')) {
            this.store.setEmail(params.get('email') as string);
        } else {
            this.store.meta.emailVisible = true;
        }
    }

    @action
    private onResendCodeClick(e: MouseEvent) {
        console.log("Resend clicked");
        e.preventDefault();
        if (this.store.meta.isValid) {
            // this.store.meta.processing = true;
            // this.store.msg = "";
            // confirm(this.store.fields.code.value, this.store.fields.email.value)
            //     .then(result => action(() => {
            //         console.log(result);
            //         this.store.meta.processing = false;
            //         if (result.errorMessage) {
            //             this.error = result.errorMessage;
            //             this.errorCode = result.errorCode as string;
            //             if (this.errorCode === "CodeMismatchException") {
            //                 this.store.fields.email.visible = true;
            //             }
            //         } else {
            //             //success
            //             alert('SUCCES');
            //             // goTo("confirmation?msg=ACCOUNT_CREATED");
            //         }
            //     }));
        }
    }

    @action
    private onConfirmClick(e: MouseEvent) {
        console.log("Confirm clicked");
        e.preventDefault();
        if (this.store.meta.isValid) {
            this.store.meta.processing = true;
            this.store.msg = "";
            confirm(this.store.fields.code.value, this.store.fields.email.value)
                .then(action("confirmSuccess", result => {
                    this.store.meta.processing = false;
                    if (result.errorMessage) {
                        console.log("Confirmation error: " + result.errorMessage);
                        this.error = result.errorMessage;
                        this.errorCode = result.errorCode as string;
                        if (this.errorCode === "CodeMismatchException") {
                            this.store.meta.emailVisible = true;
                        }
                    } else {
                        //success
                        useGoTo("/dashboard");
                    }
                }))
                .catch(error => console.log(error));
        }
    }

    render() {
        const store = this.store;

        return (
            <React.Fragment>
                <Header />
                {/* The Header components needs a header tag */}
                <header className="header text-white text-center text-lg-left" style={{backgroundColor: "#24292e", height: "0px", padding: "0px"}}></header>
                
                <main className="main-content" id="contentSignupPage">
                    <React.Fragment>
                        <section className="section text-center">
                            <div className="container">
                                <h2 className="text-center fw-200">Sign-up</h2>
                                
                                {this.error !== "" && 
                                    <div className="row gap-y">
                                        <div className="col-md-6 mx-md-auto">
                                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                {this.error}
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.store.msg !== "" && 
                                    <div className="row gap-y">
                                        <div className="col-md-6 mx-md-auto">
                                            <div id="messageDiv" className="alert alert-success alert-dismissible fade show" role="alert">
                                                {this.store.msg}
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row gap-y">
                                    <form className="col-md-4 col-xl-4 mx-auto input-border">
                                        
                                        {(this.store.fields.email.error || this.store.meta.emailVisible) && 
                                            <div className="form-group">
                                                <input id="emailTxt" type="text" className="form-control form-control-lg" placeholder="Email" 
                                                    value={store.fields.email.value}
                                                    onChange={(e) => store.onFieldChange("email", e.target.value)}
                                                />
                                            </div>
                                        }
                                        
                                        <div className="form-group">
                                            <input id="codeTxt" type="text" className="form-control form-control-lg" placeholder="Code" 
                                                value={store.fields.code.value}
                                                onChange={(e) => store.onFieldChange("code", e.target.value)}
                                            />
                                        </div>
        
                                        <button id="confirmCodeBtn" disabled={!this.store.meta.isValid || this.store.meta.processing} className="btn btn-block btn-xl btn-success" onClick={this.onConfirmClick.bind(this)}>Confirm</button>
                                                                                    
                                        <p className="small mt-3 opacity-70">
                                            {(!this.store.fields.email.error && !this.store.meta.processing)
                                                ? <a href="#" id="requestNewCodeBtn" onClick={this.onResendCodeClick.bind(this)}>Request new code</a>
                                                : <a href="#" style={{cursor: "default"}}>Request new code</a>
                                            }
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </React.Fragment>
                </main>
            
            </React.Fragment>
        );
    }

}
