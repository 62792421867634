import React, { Component, MouseEvent } from 'react';
import { PricePanel } from '../../components/PricePanel';
import { Header } from '../../components/Header';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../components/Link';
import Validator from 'validatorjs';
import { signUp } from '../../service/APIProxy';


type Fields = {[K in string]: {value: any, error: string | false, rule: string}};
class SignupStore {
    @observable
    fields: Fields = {
        name: {
            value: '',
            error: '',
            rule: 'required'
        },
        email: {
          value: '',
          error: '',
          rule: 'required|email'
        },
        password: {
          value: '',
          error: false,
          rule: 'required|regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/' //(?=.*[\.\,\?!@#\$%\^&\*])
        },
        passwordRepeat: {
            value: '',
            error: false,
            rule: 'required|same:password'
        },
    };

    meta = {
      isValid: false,
      error: null,
      processing: false
    };

    plan = '';
    showPlans = true;

    @action
    setPlan(plan: string) {
        this.plan = plan;
    }

    @action
    setShowPlans(show: boolean) {
        this.showPlans = show;
    }

    @action
    onFieldChange(field: string, value: any) {
        this.fields[field].value = value;
        const validationConf:any = {};
        const validationValue:any = {};
        
        Object.keys(this.fields).forEach((field) => validationConf[field] = this.fields[field].rule);
        Object.keys(this.fields).forEach((field) => validationValue[field] = this.fields[field].value);
        const validation = new Validator(validationValue, validationConf);

        this.meta.isValid = validation.passes() as boolean;
        this.fields[field].error = validation.errors.first(field);
    };
}

@observer
export class SingupPage extends Component {

    @observable
    private store = new SignupStore();

    @observable
    private error = "";

    @action
    private onSignUpClick(e: MouseEvent) {
        e.preventDefault();
        if (this.store.meta.isValid) {
            this.store.meta.processing = true;
            signUp(this.store.fields.name.value, this.store.fields.password.value, this.store.fields.email.value)
                .then(result => {
                    console.log(result);
                    this.store.meta.processing = false;
                    if (result.errorMessage) {
                        this.error = result.errorMessage;

                        if (result.errorMessage === "UsernameExistsButNotConfirmedException") {
                            useGoTo("/account/confirmation", "msg=ACCOUNT_EXISTS&email=" + encodeURIComponent(this.store.fields.email.value));
                        }
                    } else {
                        //success
                        useGoTo("/account/confirmation", "msg=ACCOUNT_CREATED&email=" + encodeURIComponent(this.store.fields.email.value));
                    }
                });
        }
    }

    render() {
        const store = this.store;
        const params = new URLSearchParams(window.location.search);

        if (params.get('plan')) {
            this.store.setPlan(params.get('plan') as string);
            this.store.setShowPlans(false);
        }

        return (
            <React.Fragment>
                <Header />
                {/* The Header components needs a header tag */}
                <header className="header text-white text-center text-lg-left" style={{backgroundColor: "#24292e", height: "0px", padding: "0px"}}></header>
                
                <main className="main-content" id="contentSignupPage">
                    {this.store.showPlans && <PricePanel onSignUpPage={true} /> }

                    {!this.store.showPlans && 
                        <React.Fragment>
                            <section className="section text-center">
                                <div className="container">
                                    <h2 className="text-center fw-200">Sign-up</h2>
                                    {this.error !== "" && 
                                        <div className="row gap-y">
                                            <div className="col-md-6 mx-md-auto">
                                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                    {this.error}
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="row gap-y">
                                        <form className="col-md-4 col-xl-4 mx-auto input-border">
                                            <div className="form-group">
                                                <input id="nameTxt" type="text" className="form-control form-control-lg" placeholder="Name" 
                                                    value={store.fields.name.value}
                                                    onChange={(e) => store.onFieldChange("name", e.target.value)}
                                                />
                                            </div>
        
                                            <div className="form-group">
                                                <input id="emailTxt" type="text" className={store.fields.email.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Email"
                                                    value={store.fields.email.value}
                                                    onChange={(e) => store.onFieldChange("email", e.target.value)}
                                                />
                                            </div>
        
                                            <div className="form-group">
                                                <input id="passwordTxt" type="password" className={store.fields.password.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Password" 
                                                    value={store.fields.password.value}
                                                    onChange={(e) => store.onFieldChange("password", e.target.value)}
                                                />
                                                <div className="invalid-feedback">Must have at least 8 characters, at least one upper-case, one lower-case and one number.</div>
                                            </div>

                                            <div className="form-group">
                                                <input id="passwordRepeatTxt" type="password" className={store.fields.passwordRepeat.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Repeat password" 
                                                    value={store.fields.passwordRepeat.value}
                                                    onChange={(e) => store.onFieldChange("passwordRepeat", e.target.value)}
                                                />
                                            </div>
        
                                            <button id="confirmSignupBtn" disabled={!this.store.meta.isValid || this.store.meta.processing} className="btn btn-block btn-xl btn-success" onClick={this.onSignUpClick.bind(this)} >Sign up</button>
                                            <p className="small mt-3 opacity-70">or <Link id="loginBtn" to="account/login">Login to your account</Link></p>
                                            
                                        </form>
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>
                    }
                </main>
            
            </React.Fragment>
        );
    }

}
