import React, { Component, MouseEvent } from 'react';
import { PricePanel } from '../components/PricePanel';
import { Header } from '../components/Header';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../components/Link';
import { userService } from '../service/UserService';
import { HelpOptionsPanel } from './knowledge/help/HelpOptionsPanel';

@observer
export class DashboardPage extends Component<{}> {

    constructor(props: {}) {
        super(props);
        console.log("Logged: " + userService.user.logged);
        if (!userService.user.logged) {
            useGoTo("/account/login");
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header navClassName="navbar navbar-expand-lg navbar-dark navbar-stick-dark" />
                <header className="header" style={{backgroundImage: "linear-gradient(135deg, #f9f7ff 0%, #fff 50%, #f6f3ff 100%)"}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1>SimulationEngine.io studio</h1>
                                <p className="lead mt-5 mb-8">Launch our online simulation studio to start modeling your simulation. Explorer our simulation examples and showcases to learn more about the SimulationEngine.io features</p>
                                <p><a className="btn btn-lg btn-round btn-info" href="https://app.simulationengine.io" target="_blank">Launch Simulation Studio</a></p>
                            </div>
                            <div className="col-lg-5 ml-auto d-none d-lg-block">
                                <img src="/img/laptop-1.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </header>
                
                <main className="main-content">
                    <HelpOptionsPanel />
                </main>
            </React.Fragment>
        );
    }
}
