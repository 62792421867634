import React from 'react';
import { PricePanel } from '../components/PricePanel';
import { Header } from '../components/Header';

export function HomeLandPage() {
  return (
        <React.Fragment>
            <Header />
            <header id="home" className="header text-white h-fullscreen text-center text-lg-left" style={{backgroundColor: "#24292e"}}>
                <canvas className="constellation" data-color="rgba(255,255,255,0.3)"></canvas>
                <div className="container">
                    <div className="row align-items-center h-100">

                        <div className="col-lg-6">
                            <h1>SimulationEngine.io</h1>
                            <p className="lead mt-5 mb-8">
                                Drag-and-drop simulation modelling<br/>
                                Cloud simulation execution<br/>
                                Made simple
                            </p>
                            <p className="gap-xy">
                            <a id="learnMoreBtn" className="btn btn-round btn-outline-light mw-150" href="#section-features">Learn more</a>
                            <a id="startFreeTrialBtn" className="btn btn-round btn-light mw-150" href="#section-pricing">Start for Free</a>
                            </p>
                        </div>

                        <div className="col-lg-5 ml-auto">
                            <img className="mt-5" src="img/laptop-1.png" alt="img" />
                        </div>
                    </div>
                    <div className="col-12 align-self-end text-center pb-7">
                        <a className="scroll-down-1 scroll-down-white" href="#main-content"><span></span></a>
                    </div>
                </div>
            </header>
            
            <main className="main-content" id="main-content">

                {/* <!--
                |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                | Features
                |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                !--> */}
                <section id="section-features" className="section">
                    <div className="container">
                        <div className="row gap-y align-items-center">
                            <div className="col-md-6 ml-auto">
                                <h4>Drag, drop, simulate, iterate.</h4>
                                <p>Drag and Drop components ready to model your business processes. No coding required, 
                                    SimulationEngine.io offers components that represent your business processes, 
                                    building simulation models adherent to your reality.
                                </p>
                            </div>

                            <div className="col-md-5 order-md-first">
                                <img src="img/simulation.gif" alt="..." />
                            </div>
                        </div>


                        <hr className="my-8" />


                        <div className="row gap-y align-items-center">
                            <div className="col-md-6 mr-auto">
                                <h4>No download. Installation. or Powerfull CPU needed</h4>
                                <p>SimulationEngine.io web interface requires no installation. It is ready to use whenever you need it.</p>
                                <p>The simulation runs in the cloud, not on your computer.</p>
                            </div>

                            <div className="col-md-5">
                                <img src="img/laptop-1.png" alt="..." />
                            </div>
                        </div>


                        <hr className="my-8" />


                        <div className="row gap-y align-items-center">
                            <div className="col-md-6 ml-auto">
                                <h4>Customizable components</h4>
                                <p>Use the widely known JavaScript language to customize each simulation component.</p>
                                <p>When the components behavior are not enough for your needs, you can write scripts to customize it.</p>
                                <p></p>
                            </div>

                            <div className="col-md-5 order-md-first">
                                <img src="img/script.png" alt="..." />
                            </div>
                        </div>
                    </div>
                </section>


                <PricePanel onSignUpPage={false} />


                {/* <!--
                |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                | FAQ
                |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                !--> */}
                <section id="section-faq" className="section">
                    <div className="container">
                        <header className="section-header">
                            <h2>Frequently Asked Questions</h2>
                            <hr />
                            <p>Got a question? We've got answers. If you have some other questions, contact us using email.</p>
                        </header>


                        <div className="row gap-y">
                            <div className="col-md-6 col-xl-4">
                                <h5>Can I use your service for free?</h5>
                                <p>Of course. We are happy to offer a free plan for anyone who wants to use our service.</p>
                            </div>

                            <div className="col-md-6 col-xl-4">
                                <h5>Is there any limit on the free plan?</h5>
                                <p>Yes. To offer it for free, each simulation can only run for up to 15 seconds, as well as a maximum of 1 hour combined.</p>
                            </div>

                            {/* <div className="col-md-6 col-xl-4">
                                <h5>Can I cancel my subscription?</h5>
                                <p>You can cancel your subscription anytime. Once the subscription is cancelled, you will not be charged again. You will continue to have access to your account until your current subscription expires.</p>
                            </div>


                            <div className="col-md-6 col-xl-4">
                                <h5>How long are your contracts?</h5>
                                <p>Currently, we only offer a monthly subscription. You can upgrade or cancel your monthly subscription at any time with no further obligation.</p>
                            </div> */}


                            {/* <div className="col-md-6 col-xl-4">
                                <h5>Can I update my card details?</h5>
                                <p>Yes. Go to your account to update your payment information.</p>
                            </div> */}

                            {/* <div className="col-md-6 col-xl-4">
                                <h5>Can I request a refund?</h5>
                                <p>Unfortunately, not. We do not issue full or partial refunds for any reason.</p>
                            </div> */}

                            {/* <div className="col-md-6 col-xl-4">
                                <h5>Is this a secure site?</h5>
                                <p>Absolutely. We follow industry best practices which guarantee your safety and security. 
                                    All billing information is stored on our payment processing partner, which has the most stringent level of certification available.</p>
                            </div> */}

                        </div>

                    </div>
                </section>

            </main>
        </React.Fragment>
  );
}
