import React, { MouseEvent } from 'react';
import { Link, useGoTo } from './Link';
import { observer } from 'mobx-react';
import { userService } from '../service/UserService';
import { action } from 'mobx';

interface HeaderProps {
    navClassName?: string
}

@observer
export class Header extends React.Component<HeaderProps> {
    constructor(props: HeaderProps) {
        super(props);
    }

    @action
    private onLogoutClick(e: MouseEvent) {
        e.preventDefault();
        userService.clean();
        useGoTo("/");
    }

    render() {
        const className = this.props.navClassName ? this.props.navClassName : "navbar navbar-expand-lg navbar-light navbar-stick-dark"
        return (
            <React.Fragment>
                <nav className={className} data-navbar={"sticky"}>
                    <div className="container">

                    <div className="navbar-left mr-4">
                        <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                        <a className="navbar-brand" href="/">
                            <img className="logo-light" src="/img/logo_invert.png" alt="logo" />
                            <img className="logo-dark" src="/img/logo.jpg" alt="logo" />
                        </a>
                    </div>

                    <section className="navbar-mobile">
                        <nav className="nav nav-navbar nav-text-normal mr-auto">
                            <a id ="featuresNav" className="nav-link" href="/#section-features">Features</a>
                            <a id ="pricingNav" className="nav-link" href="/#section-pricing">Pricing</a>
                            <a id ="supportNav" className="nav-link" href="/support">Support</a>
                            {/* <a id ="blogNav" className="nav-link" href="/#">Blog</a> */}
                        </nav>

                        {!userService.user.logged &&
                            <React.Fragment>
                                <div className="d-none d-stick-block align-items-center">
                                    <Link id="signupMenu" className="btn btn-sm btn-round btn-primary" to="account/signup">Sign up</Link>
                                    <Link id="loginMenu" className="btn btn-sm btn-link text-dark ml-lg-5 mr-2" to="account/login">Login</Link>
                                </div>

                                <div className="d-stick-none align-items-center">
                                    <Link id="loginDarkMenu" className="btn btn-sm btn-round btn-success text-dark ml-lg-5 mr-2" to="account/login">Login</Link>
                                </div>
                            </React.Fragment>
                        }

                        {userService.user.logged &&
                            <React.Fragment>
                                <div className="dropdown ml-lg-5">
                                    <span className="dropdown-toggle no-caret" data-toggle="dropdown">
                                        <img className="avatar avatar-xs" src="/img/avatar.jpg" alt="user" />
                                    </span>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="https://app.simulationengine.io" target="_blank">Simulation studio</a>
                                        <a className="dropdown-item" href="#">Settings</a>
                                        <div className="dropdown-divider"></div>
                                        <button onClick={this.onLogoutClick.bind(this)} className="dropdown-item">Logout</button>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </section>

                    </div>
                </nav>
            </React.Fragment>
        );
    }
}