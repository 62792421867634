import React, { Component, MouseEvent } from 'react';
import { Header } from '../../../components/Header';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../../components/Link';
import { mergeSplit, batchDeBatch, assemble } from './ExamplesPage';

@observer
export class HelpAggregatorComponentsPage extends Component<{}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Header navClassName="navbar navbar-expand-lg navbar-light navbar-stick-dark" />
                <header className="header text-white bg-dark pt-9 pb-5" style={{backgroundImage: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)"}}>
                    <div className="container">
                        <h3>Knowledge Base</h3>
                    </div>
                </header>
                
                <main className="main-content">
                    <nav className="bb-1" aria-label="breadcrumb">
                        <div className="container">
                        <ol className="breadcrumb px-0">
                            <li className="breadcrumb-item"><a href="/knowledge">Knowledge Base</a></li>
                            <li className="breadcrumb-item"><a href="/knowledge">Help</a></li>
                            <li className="breadcrumb-item">Aggregator components</li>
                        </ol>
                        </div>
                    </nav>

                    <div className="container">
                        <div className="row">

                            {/* 
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                            | Article
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒ 
                            */}
                            <div className="col-md-7 col-xl-8 mr-md-auto py-8">
                                <article>
                                    <h2 className="fw-200">Aggregator components</h2>
                                    <p className="lead">This article describes all the aggregator components, free to all users. 
                                    These components enable you to: aggregate individual simulation entities in batches of entities; 
                                    assemble multiple entities into a new one; merge different entities flow into one, 
                                    or split one flow into many others.
                                    </p>
                                    <hr />

                                    <h4 id="QuickReference">Quick reference</h4>
                                    
                                    <p><img style={{height: "30px", marginRight: "5px"}} src="/kb/help/mergeSplit.png"/>
                                    <strong>Merge/Split: </strong>It allows you to Merge one or multiple incoming flows of entities and split the merged flow into 
                                    one or multiple output flows Randomly or using Round robin. See an example <a target="_blank" href={mergeSplit}>here</a>.
                                    </p>

                                    <br />
                                    <p><img style={{height: "30px", marginRight: "5px"}} src="/kb/help/batch.png"/>
                                    <strong>Batch: </strong>It allows you to batch entities from one incoming flow. The Batch is output when it has the necessary number of entities. 
                                    See an example <a target="_blank" href={batchDeBatch}>here</a>
                                    </p>

                                    <br />
                                    <p><img style={{height: "30px", marginRight: "5px"}} src="/kb/help/deBatch.png"/>
                                    <strong>DeBatch: </strong>It reverses a Batch entity outputting the raw entities used to create the Batch. See an example <a target="_blank" href={batchDeBatch}>here</a>
                                    </p>


                                    <br />
                                    <p><img style={{height: "30px", marginRight: "5px"}} src="/kb/help/assemble.png"/>
                                    <strong>Assemble: </strong>It collects entities from its origins, in quantities specified and outputs a new assembled entity. See an example <a target="_blank" href={assemble}>here</a>
                                    </p>

                                    <br />
                                    
                                    <h4>Detailed documentation</h4>

                                    <h4 id="MergeSplit">Merge/Split</h4>
                                    <p><img style={{height: "30px"}} src="/kb/help/mergeSplit.png"/>
                                    This component has two distinct functions: to merge incoming entity flows and to split outgoing ones. You can use both functions combined or individually.</p> 
                                    
                                    <p>
                                        The Merge/Split is a multi-origin component, i.e. it can receive entities from multiple origins (e.g. producers, decisions). Origins are set by drawing an edge from them to the queue component. Each entity received from its origins is merged into a unified flow, in the same order they are received. Entities received are immediately sent to the split function.
                                    </p>
                                    
                                    <p>
                                    The Merge/Split is a multi-destination, i.e. it can send entities to multiple destinations (e.g. servers, decisions). Destinations are set by drawing an edge from the Merge/Split to the destination component.
                                    </p>

                                    <div className="row gap-y align-items-center">

                                        <div className="col-md-6 text-md-left">
                                            <p>
                                            Only one destination is notified when an entity is available. The destination is selected Randomly or using a Round Robin procedure. On the Round Robin procedure destinations are put in a list, and the first entity is delivered to the first destination, the second entity is delivered to the second destination until all destinations get one entity and the process start over with the first destination.
                                            </p>
                                        </div>

                                        <div className="col-md-5 mx-auto text-md-right">
                                            <img className="rounded-md" style={{height: "150px"}} src="/kb/help/mergeSplit_result.png" />
                                        </div>

                                    </div>
                                                                        

                                    <h6>Examples of usage:</h6>
                                    <ul>
                                        <li>Road traffic merger</li>
                                        <li>Combine different supply lines into a unified processing line</li>
                                        <li>Evenly split products from production lines into different storage areas</li>
                                    </ul>

                                    <br />


                                    <h4 id="Batch">Batch</h4>
                                    <p><img style={{height: "30px"}} src="/kb/help/batch.png"/>&nbsp;
                                        This component combines a set of entities in a new Batch entity, containing the entities that were combined.
                                    </p> 
                                    
                                    <p>
                                        The Batch component is a single-origin component, i.e. it can receive entities from one origin only. Moreover, the origin must be a Queue similarly to a Server. This is necessary to hold entities while the Batch is processed. The origin is set by drawing an edge from the origin to the Batch component. 
                                    </p>
                                    
                                    <p>
                                        The Batch is a single-destination, i.e. it can send entities to one destination. The destination is set by drawing an edge from the Batch to the destination component.
                                    </p>

                                    <div className="row gap-y align-items-center">

                                        <div className="col-md-6 text-md-left">
                                            <p>
                                            The batch entity will be created with a fixed number of entities, its size. The Batch is held until the size is reached. After the Batch is formed, it is sent to the batch internal server which process it for the time defined on the Processing time property. If your Batch does not require a process time, set it to Constant with value 0. After the processing is complete the new Batch is sent to the Batch destination.
                                            </p>
                                        </div>

                                        <div className="col-md-5 mx-auto text-md-right">
                                            <img className="rounded-md" style={{height: "200px"}} src="/kb/help/batch_result.png" />
                                        </div>

                                    </div>
                                                                        

                                    <h6>Examples of usage:</h6>
                                    <ul>
                                        <li>Packing of bulk products in a bag/box</li>
                                        <li>Creation of a pallet of bulk products for transportation</li>
                                    </ul>

                                    <br />

                                    <h4 id="DeBatch">DeBatch</h4>
                                    <p><img style={{height: "30px"}} src="/kb/help/deBatch.png"/>&nbsp;
                                    This component opens up a Batch entity and outputs all the entities used to build the Batch.</p> 
                                    
                                    <p>
                                        The DeBatch component is a single-origin component, i.e. it can receive entities from one origin only. Moreover, the origin must be a Queue similarly to a Server. This is necessary to hold entities while the de-batching is executed. The origin is set by drawing an edge from the origin to the DeBatch component. 
                                    </p>
                                    
                                    <p>
                                        The DeBatch is a single-destination, i.e. it can send entities to one destination. The destination is set by drawing an edge from the DeBatch to the destination component.
                                    </p>

                                    <div className="row gap-y align-items-center">

                                        <div className="col-md-6 text-md-left">
                                            <p>
                                                The DeBatch component receives the batch entity, and process it to extract the raw entities from it. The processing is executed for the duration defined on the Processing time distribution.
                                            </p>

                                            <p>
                                                After the processing is done, each entity is sent to the destination, one by one in no particular order.
                                            </p>

                                            <p>
                                                The DeBatch component is held busy by the batch entity from the time it is received to the time the last entity is delivered to the destination, just after that it becomes available to process the next Batch.
                                            </p>
                                        </div>

                                        <div className="col-md-5 mx-auto text-md-right">
                                            <img className="rounded-md" style={{height: "200px"}} src="/kb/help/deBatch_result.png" />
                                        </div>

                                    </div>
                                                                        

                                    <h6>Examples of usage:</h6>
                                    <ul>
                                        <li>Unpacking bags/boxes of products</li>
                                        <li>Unload products from a pallet</li>
                                    </ul>

                                    <br />
                                    

                                    <h4 id="Assemble">Assemble</h4>
                                    <p><img style={{height: "30px"}} src="/kb/help/assemble.png"/>&nbsp;
                                        This component combines entities received from its origins to assemble a new entity. Each new entity is assembled after all required quantities of the incoming entities are available.
                                    </p> 
                                    
                                    <p>
                                        The Assemble component is a multi-origin component, i.e. it can receive entities from multiple origins. The origins must be a Queue similarly to a Server. This is necessary to hold entities while the assembling process is executed. The origins are set by drawing an edge from the origin to the Assemble component. The quantities for each origin is set on the Assemble property after the edge is drawn.
                                    </p>
                                    
                                    <p>
                                        The Assemble is a single-destination, i.e. it can send entities to one destination. The destination is set by drawing an edge from the Assemble to the destination component.
                                    </p>

                                    <div className="row gap-y align-items-center">

                                        <div className="col-md-6 text-md-left">
                                            <p>
                                                When all required entities are available for a new assembled entity, they are collected from the origins and sent to the Assemble internal server for the assembling process, which takes the amount of time defined by the Processing time distribution.
                                            </p>

                                            <p>
                                                After the processing is done the assembled entity is made available to the destination.
                                            </p>
                                        </div>

                                        <div className="col-md-5 mx-auto text-md-right">
                                            <img className="rounded-md" style={{height: "250px"}} src="/kb/help/assemble_result.png" />
                                        </div>

                                    </div>
                                                                        

                                    <h6>Examples of usage:</h6>
                                    <ul>
                                        <li>Assemble parts of a product in a production line</li>
                                        <li>Mix raw products to form a new derivated product</li>
                                    </ul>

                                    <br />

                                </article>
                            </div>


                            {/* 
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                            | Sidebar
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒ 
                            */}
                            <div className="col-md-4 col-xl-3 d-none d-md-block">
                                <aside className="sidebar">
                                <h6>Topics</h6>
                                <ul className="nav flex-column">
                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#QuickReference">Quick reference</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#MergeSplit">Merge/Split</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#Batch">Batch</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#DeBatch">DeBatch</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#Assemble">Assemble</a>
                                    </li>


                                </ul>
                                </aside>
                            </div>

                        </div>
                    </div>
                    
                </main>
            </React.Fragment>
        );
    }
}
