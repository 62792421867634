import React, { Component, MouseEvent } from 'react';
import { Header } from '../../components/Header';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../components/Link';
import Validator from 'validatorjs';
import { recoverPassword, resetPassword } from '../../service/APIProxy';


type Fields = {[K in string]: {value: any, error: string | false, rule: any}};
class Store {
    @observable
    fields: Fields = {
        email: {
          value: '',
          error: '',
          rule: 'required|email'
        },
        code: {
            value: '',
            error: '',
            rule: [{ required_if: ['requestCode', true] }]
        },
        password: {
          value: '',
          error: false,
          rule: [
              { required_if: ['requestCode', true] },
              { regex: '/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/'}
          ]
        },
        passwordRepeat: {
            value: '',
            error: false,
            rule: [
                { required_if: ['requestCode', true] },
                { same: 'password'}
            ]
        },
        requestCode: {
            value: false,
            error: '',
            rule: 'required'
        }
    };

    meta = {
      isValid: false,
      error: null,
      processing: false
    };

    @action
    onFieldChange(field: string, value: any) {
        this.fields[field].value = value;
        const validationConf:any = {};
        const validationValue:any = {};
        
        Object.keys(this.fields).forEach((field) => validationConf[field] = this.fields[field].rule);
        Object.keys(this.fields).forEach((field) => validationValue[field] = this.fields[field].value);
        const validation = new Validator(validationValue, validationConf);
        console.log(validation);
        this.meta.isValid = validation.passes() as boolean;
        this.fields[field].error = validation.errors.first(field);
    };
}

@observer
export class RecoverPasswordPage extends Component<{}> {

    @observable
    private store = new Store();

    @observable
    private error = "";

    @observable
    private msg = "";

    constructor(props: {}) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        if (params.get("p")) {
            console.log("Requesting code.");
            action(() => {
                this.store.fields.requestCode.value = true;
                this.msg = "Please, verify your email for the verification code we have sent.";
            })();
        }

        if (params.get("email")) {
            console.log("Email given.");
            action(() => {
                this.store.fields.email.value = params.get("email");
            })();
        }
    }
    

    @action
    private onRecoverClick(e: MouseEvent) {
        e.preventDefault();
        if (this.store.meta.isValid) {
            this.store.meta.processing = true;

            const setNew = this.store.fields.requestCode.value === true;
            const promise = setNew 
                ? resetPassword(this.store.fields.code.value, this.store.fields.email.value, this.store.fields.password.value)
                : recoverPassword(this.store.fields.email.value);
            
            promise.then(action(result => {
                    console.log(result);
                    this.store.meta.processing = false;
                    if (result.errorMessage) {
                        this.error = result.errorMessage;
                    } else {
                        if (this.store.fields.requestCode.value === true) {
                            //Send to login
                            useGoTo("/account/login", "email=" + encodeURIComponent(this.store.fields.email.value));
                        } else {
                            //success
                            useGoTo("/account/recover", "p=requestCode&email=" + encodeURIComponent(this.store.fields.email.value));
                        }
                    }
                }));
        }
    }

    render() {
        const store = this.store;

        return (
            <React.Fragment>
                <Header />
                <header id="home" className="header h-fullscreen text-center" style={{backgroundColor: "#24292e"}}>
                    <canvas className="constellation" data-color="rgba(255,255,255,0.3)"></canvas>
                    <div className="container">
                        {this.error !== "" && 
                            <div className="row gap-y">
                                <div className="col-md-6 mx-md-auto">
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        {this.error}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.msg !== "" && 
                            <div className="row gap-y">
                                <div className="col-md-6 mx-md-auto">
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        {this.msg}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row align-items-center h-100" style={{justifyContent: "center"}}>
                            
                            <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                                <h5 className="mb-7">Recover your password</h5>
                                <form>
                                    <div className="form-group">
                                        <input type="text" className={store.fields.email.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Email"
                                            value={store.fields.email.value}
                                            onChange={(e) => store.onFieldChange("email", e.target.value)}
                                        />
                                    </div>
                                    {this.store.fields.requestCode.value &&
                                        <div className="form-group">
                                            <input type="text" className={store.fields.code.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Code"
                                                value={store.fields.code.value}
                                                onChange={(e) => store.onFieldChange("code", e.target.value)}
                                            />
                                        </div>
                                    }
                                    {this.store.fields.requestCode.value &&
                                        <React.Fragment>
                                            <div className="form-group">
                                                <input type="password" className={store.fields.password.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Password" 
                                                    value={store.fields.password.value}
                                                    onChange={(e) => store.onFieldChange("password", e.target.value)}
                                                />
                                                <div className="invalid-feedback">Must at least have 8 characters, at least one upper-case, one lower-case and one number.</div>
                                            </div>

                                            <div className="form-group">
                                                <input type="password" className={store.fields.passwordRepeat.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Repeat password" 
                                                    value={store.fields.passwordRepeat.value}
                                                    onChange={(e) => store.onFieldChange("passwordRepeat", e.target.value)}
                                                />
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className="form-group">
                                        <button disabled={!this.store.meta.isValid || this.store.meta.processing}
                                                className="btn btn-block btn-primary" 
                                                onClick={this.onRecoverClick.bind(this)}>Reset password</button>
                                    </div>
                                </form>
                                {!this.store.fields.requestCode.value &&
                                    <p className="text-center text-muted small-2"><Link id="haveCodeBtn" className="text-muted small-2" to="account/recover?p=requestCode">I have a code</Link></p>
                                }
                            </div>
                        </div>
                    </div>
                </header>
            
            </React.Fragment>
        );
    }

}
