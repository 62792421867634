

const hostname = window.location.hostname;
let ENDPOINT: String;
if (hostname === "localhost") {
    ENDPOINT = "https://websiteapi-preprod.simulationengine.io";
} else if (hostname === "preprod.app.simulationengine.io") {
    ENDPOINT = "https://websiteapi-preprod.simulationengine.io";
} else {
    ENDPOINT = "https://websiteapi.simulationengine.io";
}

const UNKOWN_ERROR = "Sorry we found a error. Please try again."

export function login(email: string, password: string):Promise<{token?: string, errorMessage?: string}> {
    return fetch(ENDPOINT + "/v1/account/login", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ email, password})
    }).then(response => {
        console.log(response);
        if (response.status === 200) {
            return response.json().then(result => {
                console.log(result);
                return { token: result.token as string };
            }); //success
        } else {
            return response.json().then(result => {
                console.log(result);
                if (result.error) {
                    // NotAuthorizedException UserNotConfirmedException
                    return { errorMessage: getMessage(result.error.errorCode), token: "" };
                } else {
                    return { errorMessage: UNKOWN_ERROR, token: "" };
                }
            });
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
        //network related issues
        return {errorMessage: "Failed to connect to servers. Verify your internet connection and try again."};
    });
}

export function signUp(name: string, password: string, email: string):Promise<{errorMessage?: string}> {
    return fetch(ENDPOINT + "/v1/account", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ name, password, email})
    }).then(response => {
        console.log(response);
        if (response.status === 200) {
            return {}; //success
        } else {
            return response.json().then(result => {
                console.log(result);
                if (result.error) {
                    // InvalidRequest InvalidPasswordException UsernameExistsException
                    return { errorMessage: getMessage(result.error.errorCode) };
                } else {
                    return { errorMessage: UNKOWN_ERROR };
                }
            });
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
        //network related issues
        return {errorMessage: "Failed to connect to servers. Verify your internet connection and try again."};
    });
}

export function confirm(code: string, email: string):Promise<{errorMessage?: string, errorCode?: string}> {
    return fetch(ENDPOINT + "/v1/account/confirm", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ email, code})
    }).then(response => {
        console.log(response);
        if (response.status === 200) {
            return {}; //success
        } else {
            return response.json().then(result => {
                console.log(result);
                if (result.error) {
                    // InvalidRequest ExpiredCodeException CodeMismatchException UsernameExistsButNotConfirmedException
                    return { errorMessage: getMessage(result.error.errorCode), errorCode: result.error.errorCode};
                } else {
                    return { errorMessage: UNKOWN_ERROR };
                }
            });
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
        //network related issues
        return {errorMessage: "Failed to connect to servers. Verify your internet connection and try again."};
    });
}



export function recoverPassword(email: string):Promise<{errorMessage?: string}> {
    return fetch(ENDPOINT + "/v1/account/forgotpassword", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ email})
    }).then(response => {
        console.log(response);
        if (response.status === 200) {
            return {}; //success
        } else {
            return response.json().then(result => {
                console.log(result);
                if (result.error) {
                    // No error is expected
                    return { errorMessage: getMessage(result.error.errorCode) };
                } else {
                    return { errorMessage: UNKOWN_ERROR };
                }
            });
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
        //network related issues
        return {errorMessage: "Failed to connect to servers. Verify your internet connection and try again."};
    });
}

export function resetPassword(code: string, email: string, password: string):Promise<{errorMessage?: string, errorCode?: string}> {
    return fetch(ENDPOINT + "/v1/account/newpassword", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ email, code, password})
    }).then(response => {
        console.log(response);
        if (response.status === 200) {
            return {}; //success
        } else {
            return response.json().then(result => {
                console.log(result);
                if (result.error) {
                    // InvalidRequest ExpiredCodeException CodeMismatchException
                    return { errorMessage: getMessage(result.error.errorCode), errorCode: result.error.errorCode};
                } else {
                    return { errorMessage: UNKOWN_ERROR };
                }
            });
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
        //network related issues
        return {errorMessage: "Failed to connect to servers. Verify your internet connection and try again."};
    });
}

const messages = new Map<string, string>();
messages.set("Invalid request", "Verify your request and try again.");
messages.set("InvalidRequest", "Verify your request and try again."); 

//Login
messages.set("NotAuthorizedException", "Invalid user or password.");

//SingUp
messages.set("InvalidPasswordException", "Your password does not match our security policy. It needs to contain at least one upper-case and one lower-case leters, at least one number, at least one special caracter.");
messages.set("UsernameExistsException", "This email is already register. Try to login to your account. If you forgot your password try to recover it using the 'Forgot my password' option on the login page");
messages.set("UsernameExistsButNotConfirmedException", "UsernameExistsButNotConfirmedException"); //this is not shown to user.

//Confirm
messages.set("ExpiredCodeException", "The code entered is expired. Please request a new code to confirm your account.");
messages.set("CodeMismatchException", "The code entered does not match. Please confirm the email and code.");



function getMessage(code: string) {
    const msg = messages.get(code);
    if (msg) {
        return msg;
    } else {
        return UNKOWN_ERROR;
    }
}