import React, { Component, MouseEvent } from 'react';
import { PricePanel } from '../../components/PricePanel';
import { Header } from '../../components/Header';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../components/Link';
import Validator from 'validatorjs';
import { login } from '../../service/APIProxy';
import { userService } from '../../service/UserService';


type Fields = {[K in string]: {value: any, error: string | false, rule: string}};
class LoginStore {
    @observable
    fields: Fields = {
        email: {
          value: '',
          error: '',
          rule: 'required|email'
        },
        password: {
          value: '',
          error: false,
          rule: 'required|regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/' //(?=.*[\.\,\?!@#\$%\^&\*])
        },
        rememberMe: {
            value: true,
            error: false,
            rule: ''
        }
    };

    meta = {
      isValid: false,
      error: null,
      processing: false
    };

    @action
    onFieldChange(field: string, value: any) {
        this.fields[field].value = value;
        const validationConf:any = {};
        const validationValue:any = {};
        
        Object.keys(this.fields)
            .filter(field => this.fields[field].rule !== "")
            .forEach((field) => validationConf[field] = this.fields[field].rule);
        Object.keys(this.fields).forEach((field) => validationValue[field] = this.fields[field].value);
        const validation = new Validator(validationValue, validationConf);

        this.meta.isValid = validation.passes() as boolean;
        this.fields[field].error = validation.errors.first(field);
    };
}

@observer
export class LoginPage extends Component<{}, {}> {

    @observable
    private store = new LoginStore();

    @observable
    private error = "";

    private embedded: boolean;

    constructor(props: {}) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        this.embedded = params.get('embedded') ? true : false;
    }

    componentDidMount() {
        console.log("Starting login page.");
            
        if (userService.user.logged) {
            this.afterLogin();
        }
        console.log("Login page started.");
    }

    @action
    private afterLogin() {
        if (!this.embedded) {
            useGoTo("/dashboard");
        } else {
            //This means the page is on iframe. Execute afterLogin() on parent page
            console.log("Dispatiching event: afterSELogin");
            window.parent.postMessage("afterSELogin", "*"); //As long as there is no data being passed, this is fine.
        }
    }

    @action
    private onLoginClick(e: MouseEvent) {
        e.preventDefault();
        if (this.store.meta.isValid) {
            this.store.meta.processing = true;
            login(this.store.fields.email.value, this.store.fields.password.value)
                .then(result => {
                    console.log(result);
                    this.store.meta.processing = false;
                    if (result.errorMessage) {
                        this.error = result.errorMessage;
                    } else {
                        //success
                        userService.setToken(result.token as string, this.store.fields.rememberMe.value);
                        this.afterLogin();
                    }
                });
        }
    }

    render() {
        if (this.embedded) {
            return (
                <React.Fragment>
                    {this.renderLoginForm()}
                </React.Fragment>
            );
        } else {
            return this.renderFullPage();
        }
    }

    renderFullPage() {
        return (
            <React.Fragment>
                <Header />
                <header id="home" className="header h-fullscreen text-center" style={{backgroundColor: "#24292e"}}>
                    <canvas className="constellation" data-color="rgba(255,255,255,0.3)"></canvas>
                    <div className="container">
                        {this.renderLoginForm()}
                    </div>
                </header>
            </React.Fragment>
        );
    }

    renderLoginForm() {
        const store = this.store;
        const style = !this.embedded ? {justifyContent: "center"} : {};
        return (
            <React.Fragment>
                {this.error !== "" && 
                    <div className="row gap-y">
                        <div className="col-md-6 mx-md-auto">
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {this.error}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className="row align-items-center h-100" style={style}>
                    
                    <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                        {!this.embedded && <h5 className="mb-7">Login into your account</h5> }

                        <form>
                            <div className="form-group">
                                <input id="emailTxt" type="text" className={store.fields.email.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Email"
                                    value={store.fields.email.value}
                                    onChange={(e) => store.onFieldChange("email", e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <input id="passwordTxt" type="password" className={store.fields.password.error ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Password" 
                                    value={store.fields.password.value}
                                    onChange={(e) => store.onFieldChange("password", e.target.value)}
                                />
                                <div className="invalid-feedback">Must have 8 characters, at least one upper-case, one lower-case and one number.</div>
                            </div>

                            <div className="form-group flexbox py-3">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" 
                                        checked={store.fields.rememberMe.value}
                                        onChange={(e) => store.onFieldChange("rememberMe", e.target.value)}
                                        />
                                    <label className="custom-control-label">Remember me</label>
                                </div>

                                <Link id="fogotPasswordBtn" newTab={this.embedded} className="text-muted small-2" to="account/recover">Forgot password?</Link>
                            </div>

                            <div className="form-group">
                                <button id="doLoginBtn" disabled={!this.store.meta.isValid || this.store.meta.processing} className="btn btn-block btn-primary" onClick={this.onLoginClick.bind(this)}>Login</button>
                            </div>
                        </form>

                        <p className="text-center text-muted small-2">Don't have an account? <Link id="sigupBtn" newTab={this.embedded} to="account/signup">Register here</Link></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
