import React from 'react';

interface PricePanelProps {
    onSignUpPage: boolean
}

export class PricePanel extends React.Component<PricePanelProps> {

    constructor(props: PricePanelProps) {
        super(props);
    }

    private queryStringParams() {
        return new URLSearchParams(window.location.search);
    }

    render() {
        const planSelectOnURL = this.queryStringParams().get("planSelected");
        const label = (this.props.onSignUpPage? "Select" : "Sign up");
        return (
             <section id="section-pricing" className="section bg-gray">
                <div className="container">
                    <header className="section-header">
                        <h2>Subscription plans</h2>
                        <p className="lead">SimulationEngine.io online simulation modelling tools are accessible for Free and on Paid plans.</p>
                    </header>


                    <div className="row gap-y text-center">

                    <div className="col-md-4 mx-auto mt-7 mt-md-0">
                            <div className="card card-body text-center shadow-2 hover-shadow-8 py-6">
                                <p className="lead text-dark text-uppercase fw-600 ls-1 mb-0">Start</p>
                                <p className="text-lighter"><span data-bind-radio="pricing-5" data-monthly="Monthly" data-yearly="Yearly">&nbsp;</span></p>
                                <p className="lead-7 fw-600 text-dark"><span>Free</span></p>
                                <p>
                                    Professional simulation components<br />
                                    Unlimited simulation modelling time<br />
                                    30 minutes of simulation time<br />
                                    Basic result data <br />
                                        <br />
                                        <br />
                                </p>
                                <br />
                                <div>
                                    <a id="selectFreePlanBtn" className="btn btn-round btn-primary w-200" href="/account/signup?plan=free" data-bind-href="pricing-5" data-monthly="#monthly" data-yearly="#yearly">{label}</a>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4 mx-auto mt-7 mt-md-0">
                            <div className="card card-body text-center shadow-2 hover-shadow-8 py-6">
                                <p className="lead text-dark text-uppercase fw-600 ls-1 mb-0">Personal</p>
                                <p className="text-lighter"><span data-bind-radio="pricing-5" data-monthly="Monthly" data-yearly="Yearly">Monthly</span></p>
                                <p className="lead-7 fw-600 text-dark"><span data-bind-radio="pricing-5" data-monthly="$6." data-yearly="$64.">--</span><span className="lead-4 align-text-top"></span></p>
                                <p>
                                    Professional simulation components<br />
                                    Unlimited simulation modelling time<br />
                                    30 hour of simulation time<br />
                                    Detailed result data <br />
                                    Basic support<br />
                                        <br />
                                </p>
                                <br />
                                <div>
                                    <span className="btn btn-round btn-outline-secondary w-200"  data-bind-href="pricing-5" data-monthly="#monthly" data-yearly="#yearly">Coming soon</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mx-auto mt-7 mt-md-0">
                            <div className="card card-body text-center shadow-2 hover-shadow-8 py-6">
                                <p className="lead text-dark text-uppercase fw-600 ls-1 mb-0">Professional</p>
                                <p className="text-lighter"><span data-bind-radio="pricing-5" data-monthly="Monthly" data-yearly="Yearly">Monthly</span></p>
                                <p className="lead-7 fw-600 text-dark"><span data-bind-radio="pricing-5" data-monthly="$6." data-yearly="$64.">--</span><span className="lead-4 align-text-top"></span></p>
                                <p>
                                    Professional simulation components<br />
                                    Unlimited simulation modelling time<br />
                                    30 hour of Simulation<br />
                                    Detailed result data <br />
                                    Extended support<br />
                                    Simulation debug tools<br />
                                </p>
                                <br />
                                <div>
                                    <span className="btn btn-round btn-outline-secondary w-200"  data-bind-href="pricing-5" data-monthly="#monthly" data-yearly="#yearly">Coming soon</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}