import React, { Component, Fragment } from "react";

export class HelpOptionsPanel extends Component<{}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <section className="section">
                    <div className="container">
                        <div className="row gap-y text-center">

                            <div className="col-md-4">
                                <a className="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" href="/knowledge">
                                    <p><i className="icon-book-open lead-7 text-primary"></i></p>
                                    <h5 className="fw-500 my-4">Knowledge Base</h5>
                                    <p className="mb-0">The best way to get started: tutorials, samples and other helpful articles.</p>
                                </a>
                            </div>

                            <div className="col-md-4">
                                <a className="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" href="https://stackoverflow.com/questions/tagged/simulationengine.io" target="_blank">
                                    <p><i className="icon-chat lead-7 text-primary"></i></p>
                                    <h5 className="fw-500 my-4">Community</h5>
                                    <p className="mb-0">Search for questions and anwsers by our users.</p>
                                </a>
                            </div>

                            <div className="col-md-4">
                                <a className="card px-6 py-8 shadow-11 hover-shadow-9 text-default border" href="#">
                                    <p><i className="icon-megaphone lead-7 text-primary"></i></p>
                                    <h5 className="fw-500 my-4">Customer services</h5>
                                    {/* <p className="mb-0">Let our team help you using SimulationEngine.io for your simulations.</p> */}
                                    <p className="mb-0">Contact us at: <a href="mailto:contactus@simulationengine.io">contactus@simulationengine.io</a></p>
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </section>            
            </React.Fragment>
        );
    }
}