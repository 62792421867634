import React, { Component, MouseEvent } from 'react';
import { Header } from '../../../components/Header';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../../components/Link';
import { miningScript } from './ExamplesPage';

@observer
export class ScriptingHelpPage extends Component<{}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Header navClassName="navbar navbar-expand-lg navbar-light navbar-stick-dark" />
                <header className="header text-white bg-dark pt-9 pb-5" style={{backgroundImage: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)"}}>
                    <div className="container">
                        <h3>Knowledge Base</h3>
                    </div>
                </header>
                
                <main className="main-content">
                    <nav className="bb-1" aria-label="breadcrumb">
                        <div className="container">
                        <ol className="breadcrumb px-0">
                            <li className="breadcrumb-item"><a href="/knowledge">Knowledge Base</a></li>
                            <li className="breadcrumb-item"><a href="/knowledge">Help</a></li>
                            <li className="breadcrumb-item">Scripting</li>
                        </ol>
                        </div>
                    </nav>

                    <div className="container">
                        <div className="row">

                            {/* 
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                            | Article
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒ 
                            */}
                            <div className="col-md-7 col-xl-8 mr-md-auto py-8">
                                <article>
                                    <h2 className="fw-200">Simulation scripting</h2>
                                    <p className="lead">Customize your simulation behavior.
                                    </p>
                                    <hr />

                                    <h4 id="Introduction">Introduction</h4>

                                    <p>
                                        SimulationEngine.io allows you to customize the simulation components with your own code.
                                        SimulationEngine.io can interpret Javascript code.
                                    </p>

                                    <p>
                                        All components that can be customized have the "Script" parameter, check the box and click on Edit 
                                        to open the script editor for that component. Each component script has it own scope, i.e. variables and
                                        functions defined a component script are only visible to that component script.
                                    </p>

                                    <p>
                                       The simulation itself also can have a "Script". The simulation script is global, i.e. all 
                                       other component scripts have access to variables and functions defined on the simulation script..
                                    </p>

                                    <p>
                                        If you want to skip to a pratical example, see how scripting was use on a simulation model
                                        to route trucks between loaders and dump places on a Ore mining transporat simuation.
                                        <br /><a href={miningScript} target="_blank">Open the example on SimulationEngine.io Studio</a>
                                    </p>

                                    <h4 id="LanguageFeatures">Language features</h4>
                                    <p>
                                        This section is not a compreensive list of all language features supported. It is only meant to be a quick 
                                        startup guide for people not familiar with Javascript. SimulationEngine.io supports most of the  JavaScript 1.7
                                        features.
                                    </p>

                                    <h6>Variables and constants</h6>
                                    Use the keyword "var" to define your varibales and "const" for constants. 
                                    Variables can be of type String, Number, Boolean, Array or Object. Variables are dynamicly typed, i.e. you do not
                                    specify the type and you also can change its type (usually not a good pratice). 
                                    <pre>
                                        <code>
{
`var size = 10;  //Number
var weight = 10.0; //Number
var name = "John"; //String
var values = [1, 2, 3] //Array
var age = {  //Object
    john: 30,
    mike: 1
} 
`}
                                        </code>
                                    </pre>
                                    < br/>

                                    <h6>Condition If/else</h6>
                                    If/else are used to branch the execution flow given a boolean condition.
                                    <pre>
                                        <code>
{
`if (condition) {
    //code to run if condition is true
} if else (other condition) {
    //code to run if other condition is true
} else {
    // run some other code instead
}`}
                                        </code>
                                    </pre>
                                    < br/>
                                    
                                    <h6>Looping</h6>
                                    Use for/while to iterate over a array or a range. 
                                    You can use "break" to exit a loop or "continue" to skip the current iteration and go to the next.
                                    <pre>
                                        <code>
{
`for (initializer; condition; final-expression) {
    // code to run
}

for (var i = 1; i < 10; i++) {
    // code to run

    if (continue condition) {
        continue;
    }

    if (break condition break) {
        break;
    }
}

initializer
while (condition) {
  // code to run
  final-expression
}

var = 1;
while (i < 10) {

    i++;
}
`}
                                        </code>
                                    </pre>

                                    < br/>
                                    < br/>

                                    <h4 id="CustomizeSimulation">Customizing simulation with scripts</h4>
                                    
                                    <h6 id="EntityProperties">Simulation entity properties</h6>
                                    Simulation entities (generated by Producer component) can be customized in different places, 
                                    including on the Producer script. Each entity offers one function to get a property by its name,
                                    and another function to set/update the value of a property.

                                    <pre>
                                        <code>
{
`//Get a entity property value
var w = entity.getProperty("weight");

//Set a property
entity.setProperty("weight", 10);

//Verify if a property is set
if (entity.getProperty("failure")) {
    //do something
}
`}                                      </code>
                                    </pre>

                                    <p>
                                        The entities produced by a Batch and a Assemble have special attributes you can use on your logic.
                                    </p>
                                    <p>
                                        The Batch entity will collect other entities within it and expose it a object through the "getEnttities()" function.
                                    </p>
                                    <pre>
                                        <code>
{
`//Get all entities within a Batch
var entities = batch.getEntities();

//Get the number of entities on the Batch
var size = entities.size();

//Get one entity from the batch (indexed from 0 to size-1)
var firstEntity = batch.getEntities().get(0);
`}                                      </code>
                                    </pre>


                                    <p>
                                        The Assembled entity gives access to the part entities that were used on its assembly. 
                                        The function "getParts()" returns a object with all entities used.
                                    </p>
                                    <pre>
                                        <code>
{
`//Get all entities part within a Assembled entity
var parts = assembledEntity.getParts();

//Get the number of parts
var size = parts.size();

//Get one part from the Assembled (indexed from 0 to size-1)
var part = batch.getParts().get(0);
`}                                      </code>
                                    </pre>


                                    <h5 id="SimulationComponents">Simulation components functions</h5>
                                    Each simulation components offers a set of functions you can implement to 
                                    customize the component behavior. All those functions implementation are optional, 
                                    i.e. if not implemented the component continue with its the default behavior.
                                    The functions available are listed on the examples below:
 
                                    <br/><br/>
                                    <h6 id="Producer">Producer</h6>

                                    <strong>customize:</strong> Function called by the producer after generating a new entity. 
 * The entity is passed as a parameter. No return is expected.
                                    <pre>
                                        <code>{
`function customize(entity) { 
   entity.setProperty("type", "Rice");
   entity.setProperty("weight", 3);
}`
                                        }</code>
                                    </pre>

                                    <br/>
                                    <h6 id="Server">Server</h6>

                                    <strong>computeServiceTime:</strong> Function called by the Server just before starting processing a entity to compute
                                    the time required. This function receives the entity and the random value draw from the service time distribution,
                                    and should return the simulation time (Number) that will be spent to process the entity (in the unit defined on your model).
                                    <pre>
                                        <code>{
`//In this example the function does nothing other than return the random value received
function computeServiceTime(entity, randomValue) { 
   return randomValue;
}

//In this example the function adjust the processing time based on the entity property weight
function computeServiceTime(entity, randomValue) { 
   return entity.getProperty("weight") * randomValue;
}
`
                                        }</code>
                                    </pre>

                                    <br/>
                                    <h6 id="MergeSplit">Merge/Split</h6>

                                    <strong>distribute:</strong> Function called by the Merge/Split to define where a entity
                                    that just arrived will be sent to. This function receives the entity as a parameter.
                                    It should return the name of the destination (name: shown on the component simulation properties, 
                                    which you can edit by double clicking the componenet) that will receive the entity.
                                    <pre>
                                        <code>{
`//In this example the Merge/Split has 2 destinations ["FastServer", "SlowServer"], 
//it sends twice as much entity to the "FastServer".
var count = 1;
function distribute(entity) { 
    var dest = count % 3;
    count ++;

    if (dest == 0 || dest == 1) {
        return "FastServer";
    } else {
        return "SlowServer";
    }
}`
                                        }</code>
                                    </pre>

                                    <br/>
                                    <h6 id="Assemble">Assemble</h6>

                                    <strong>computeAssembleServiceTime:</strong> The Assemble has a internal Server, thus it expose a similar
                                    function to compute the assemble time.
                                    <pre>
                                        <code>{
`//In this example the function adjust the processing time based on the entity property weight
function computeAssembleServiceTime(assembledEntity, randomValue) { 
   return assembledEntity.getProperty("weight") * randomValue;
}
`
                                        }</code>
                                    </pre>

                                    <br/>
                                    <h6 id="Batch">Batch</h6>

                                    <strong>computeBatchServiceTime:</strong> The Batch has a internal Server, thus it expose a similar
                                    function to compute the batch time.
                                    <pre>
                                        <code>{
`function computeBatchServiceTime(batch, randomValue) { 
   return batch.getProperty("weight") * randomValue;
}
`
                                        }</code>
                                    </pre>

                                    <strong>shouldFinishBatch:</strong> After a new entity is added to the batch this function is called
                                    to verify if the batch should be finilized and sent to the destination. 
                                    The Batch and the newly added Entity are passed as parameters. The function is expected
                                    to return a Boolean indicating if the batch should be finilized or not. This function, when implemented,
                                    override the Batch Size property of its component.
                                    <pre>
                                        <code>{
`//This function gets the property 'w' and sum up it
//when the sum is bigger than 50 it the bacth is finilized.
var batchW = 0;
function shouldFinishBatch(batch, newEntity) {
  batchW += newEntity.getProperty('w');
  log.info(batchW);
  if (batchW >= 50) {
     batchW = 0; return true;
  } else return false;
}
`
                                        }</code>
                                    </pre>

                                    <br/>
                                    <h6 id="DeBatch">DeBatch</h6>

                                    <strong>computeDeBatchServiceTime:</strong> The DeBatch has a internal Server, thus it expose a similar
                                    function to compute the de-batch time.
                                    <pre>
                                        <code>{
`function computeDeBatchServiceTime(batch, randomValue) { 
   return batch.getProperty("weight") * randomValue;
}
`
                                        }</code>
                                    </pre>

                                </article>
                            </div>


                            {/* 
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
                            | Sidebar
                            |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒ 
                            */}
                            <div className="col-md-4 col-xl-3 d-none d-md-block">
                                <aside className="sidebar">
                                <h6>Topics</h6>
                                <ul className="nav flex-column">
                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#Introduction">Introduction</a>
                                    </li>
                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#LanguageFeatures">Language features</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#CustomizeSimulation">Customizing simulation with scripts</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#EntityProperties">Simulation entity properties</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#SimulationComponents">Simulation components functions</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#Producer">Producer</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#Server">Server</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#MergeSplit">Merge/Split</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#Assemble">Assemble</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#Batch">Batch</a>
                                    </li>

                                    <li className="nav-item text-truncate">
                                    <i className="fa fa-file-text-o mr-2"></i>
                                    <a className="nav-link d-inline-block" href="#DeBatch">DeBatch</a>
                                    </li>
                                </ul>
                                </aside>
                            </div>

                        </div>
                    </div>
                    
                </main>
            </React.Fragment>
        );
    }
}
