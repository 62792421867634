import React from 'react';

export function Footer() {
    const params = new URLSearchParams(window.location.search);
    const embedded = params.get('embedded') ? true : false;
    
    if (embedded) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <footer className="footer bg-gray py-9">
            <div className="container">
                <div className="row gap-y">

                    {/* <div className="col-md-6 col-xl-4">
                        <p><a href="#"><img src="/img/logo.jpg" alt="logo" /></a></p>
                        <p></p>
                    </div>

                    <div className="col-6 col-md-3 col-xl-2">
                        <h6 className="mb-4 mt-1"><strong>Company</strong></h6>
                        <div className="nav flex-column">
                        <a className="nav-link" href="#">About</a>
                        <a className="nav-link" href="#">Careers</a>
                        <a className="nav-link" href="#">Contact</a>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 col-xl-2">
                        <h6 className="mb-4 mt-1"><strong>Product</strong></h6>
                        <div className="nav flex-column">
                        <a className="nav-link" href="/#section-features">Features</a>
                        <a className="nav-link" href="/#section-pricing">Pricing</a>
                        </div>
                    </div>

                    <div className="col-6 col-md-6 col-xl-2">
                        <h6 className="mb-4 mt-1"><strong>Support</strong></h6>
                        <div className="nav flex-column">
                        <a className="nav-link" href="/support">Help Center</a>
                        <a className="nav-link" href="#">FAQ</a>
                        </div>
                    </div>

                    <div className="col-6 col-md-6 col-xl-2 text-center">
                        <p><a className="btn btn-block btn-round btn-secondary" href="/account/signup?plan=free">Try it free</a></p>
                        <br />
                        <div className="social social-bordered">
                            <a className="social-facebook" target="_blank" href="https://www.facebook.com/SimulationEngineio-101373175017338"><i className="fa fa-facebook"></i></a>
                            <a className="social-twitter" target="_blank" href="https://twitter.com/SimulationE_io"><i className="fa fa-twitter"></i></a>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    );
}