import React, { Component, MouseEvent } from 'react';
import { Header } from '../../components/Header';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useGoTo } from '../../components/Link';
import { userService } from '../../service/UserService';

@observer
export class KnowledgePage extends Component<{}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Header navClassName="navbar navbar-expand-lg navbar-light navbar-stick-dark" />
                <header className="header text-white bg-dark pt-9 pb-5" style={{backgroundImage: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)"}}>
                    <div className="container">
                        <h3>Knowledge Base</h3>
                    </div>
                </header>
                
                <main className="main-content">
                    <nav className="bb-1" aria-label="breadcrumb">
                        <div className="container">
                        <ol className="breadcrumb px-0">
                            <li className="breadcrumb-item"><a href="index.html">Knowledge Base</a></li>
                        </ol>
                        </div>
                    </nav>

                    <article className="container py-8">
                        <h3>Get started</h3>
                        <br />
                        <h6>
                            <i className="fa fa-file-text-o mr-3"></i>
                            <a href="/support/started/basic">SimulationEngine.io basics</a>
                        </h6>
                        <h6>
                            <i className="fa fa-file-text-o mr-3"></i>
                            <a href="/support/started/examples">Examples</a>
                        </h6>
                        <h6>
                            <i className="fa fa-file-text-o mr-3"></i>
                            <a href="/support/help/scripting">Scripting</a>
                        </h6>
                        


                        <hr className="hr-dash my-7" />

                        <h3>Simulation component library</h3>
                        <br />
                        <h6>
                            <i className="fa fa-file-text-o mr-3"></i>
                            <a href="/support/help/basic">Basic</a>
                        </h6>
                        <h6>
                            <i className="fa fa-file-text-o mr-3"></i>
                            <a href="/support/help/aggregator">Aggregators</a>
                        </h6>

                        


                    </article>

                </main>
            </React.Fragment>
        );
    }
}
